<template>
<!--  <div class="font-weight-bold">Status history:</div>-->
  <strong>Previous status:</strong> {{statePrevious?.status}} <br>
  <strong>Date changed:</strong> {{ formatReadDateTime(statePrevious?.entered_at) }} <br>
  <strong>Updated by:</strong> {{getCausedBy}}
  <InfoModal ref="previousName">
    <template #btn-text>
      <span class="link-text">view history</span>
    </template>
    <template #title>
      Status change history
    </template>
    <template #content>
      <table class="table table-hover text-left">
        <thead>
        <tr>
          <th scope="col">Status</th>
          <th scope="col">Date</th>
          <th scope="col">Manual update</th>
          <th scope="col">Updated by</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(t, i) in stateList"
            :key="i"
        >
          <td>
            {{t.status}}<span v-if="t.closed_reason !== null"> - {{t.closed_reason}}</span>
          </td>
          <td>
            {{ formatReadDateTime(t.entered_at) }}
          </td>
          <td>
            {{ t.manual_flag === 1 ? "Yes" : "No"}}
          </td>
          <td>
            {{ t.manual_flag === 1 ? t.caused_by?.first_name + ' ' + t.caused_by?.last_name || "Automation" : "Automation" }}
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </InfoModal>
</template>

<script>
export default {
  props: {
    stateCurrent: {
      type: Object,
      required: true,
    },
    statePrevious: {
      type: Object,
      required: true,
    },
    stateList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    getCausedBy() {
      let name = null;
      if(this.statePrevious?.caused_by?.first_name) {
        name = this.statePrevious?.caused_by?.first_name + ' ' + this.statePrevious?.caused_by?.last_name
      }
      return name || 'Automation';
    },
  },
  methods: {

  },
}
</script>

<style lang="scss">

</style>